.terms-of-service {
  padding: 28px 0;
}

.terms-of-service h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 36px;
}

.terms-of-service p,
.terms-of-service section {
  margin: 0 0 20px;
}

.terms-of-service h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.terms-of-service ol,
.terms-of-service ul {
  margin-bottom: 10px;
  margin-top: 0;
}

.terms-of-service ul {
  list-style: none;
}
