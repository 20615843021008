.privacy-policy {
  padding: 28px 0;
}

.privacy-policy h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 36px;
}

.privacy-policy p,
.privacy-policy section {
  margin: 0 0 20px;
}

.privacy-policy h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.privacy-policy ol,
.privacy-policy ul {
  margin-bottom: 10px;
  margin-top: 0;
}

.privacy-policy ul {
  list-style: none;
}
