body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.play-board {
  background-image: repeating-linear-gradient(90deg, lightgray, lightgray 1px, transparent 1px, transparent 32px),
    repeating-linear-gradient(0deg, lightgray, lightgray 1px, #fff 1px, #fff 32px);
}

.edit-board {
  background-image: repeating-linear-gradient(90deg, lightblue, lightblue 1px, transparent 1px, transparent 32px),
    repeating-linear-gradient(0deg, lightblue, lightblue 1px, #fff 1px, #fff 32px);
}

.balloon-bottom-gray {
  position: relative;
  background-color: gray;
  display: inline-grid;
  vertical-align: middle;
  text-align: center;
}

.balloon-bottom-gray::before {
  content: '';
  position: absolute;
  bottom: -20%;
  left: 30%;
  display: block;
  width: 0;
  height: 0;
  border-right: 0.3em solid transparent;
  border-top: 1.0em solid gray;
  border-left: 0.8em solid transparent;
}

.balloon-bottom-orange {
  position: relative;
  background-color: orange;
  display: inline-grid;
  vertical-align: middle;
  text-align: center;
}

.balloon-bottom-orange::before {
  content: '';
  position: absolute;
  bottom: -20%;
  left: 30%;
  display: block;
  width: 0;
  height: 0;
  border-right: 0.3em solid transparent;
  border-top: 1.0em solid orange;
  border-left: 0.8em solid transparent;
}

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page > div {
  flex: 1;
}

/* TODO: bootstrapアップグレードしたら削除する */
.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}
